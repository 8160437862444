import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Deal',
    data: function () {
        return {
            privacyList: [
                {
                    id: 1,
                    title: '我们如何收集和使用您的个人信息',
                    children: [
                        {
                            id: 101,
                            content: '个⼈信息处理规则依次向您说明每项功能可能收集的个⼈信息范围、收集⽬的、收集⽅式，以及拒绝提供个⼈信息可能导致的结果。福马将严格遵循合法、正当、必要的原则，收集、使⽤⽤户信息。',
                            strong: false
                        },
                        {
                            id: 102,
                            content: '本摘要向您简要介绍我们收集的个⼈信息及⽬的，更全⾯的个⼈信息收集情况请⻅正⽂“个⼈信息的收集”部分: ',
                            children: [
                                {
                                    id: 1021,
                                    content: '创建账号: 我们会收集您的⼿机号码、微信平台的OpenID（仅使⽤微信⼀键登录时收集）。',
                                    strong: true
                                },
                                {
                                    id: 1022,
                                    content: '防控运营⻛险，保障您的账号安全，满⾜法律法规要求，开展数据分析、更好改善福马平台服务：我们会收集您的设备信息（包括设备型号，操作系统版本，设备设置，MAC地址，IMEI、Android ID 、IDFA、IDFV、OAID及其他设备标识符，SIM卡IMSI信息，SIM卡归属地，设备环境以及其他软硬件特征信息）。',
                                    strong: true
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 2,
                    title: '我们调用的设备权限',
                    children: [
                        {
                            id: 201,
                            content: '个⼈信息处理规则将向您说明福马平台可能需要调取的设备权限、调⽤的⽬的或其对应的业务功能，以及权限是否可关闭的情况。请知悉，您同意个⼈信息处理规则后，相应设备权限并不会默认开启，当涉及重要或敏感的设备权限时，我们会在您使⽤到相应业务功能时，另⾏弹窗再次征得您的同意后开启。权限开启后，您还可以随时通过设备设置关闭权限。您不同意开启权限，将不会影响其他非相关业务功能的正常使⽤。本摘要向您介绍以下我们可能申请调⽤的主要敏感权限，更全⾯的设备权限调⽤情况请⻅正⽂“系统权限调⽤“部分: ',
                            strong: false,
                            children: [
                                {
                                    id: 2010,
                                    content: '获取您的位置信息，用于显示附近车辆，确认控制无人车的安全距离',
                                    strong: true
                                },
                                {
                                    id: 2011,
                                    content: '获取您的相机功能，用于拍摄故障信息，绑定车辆',
                                    strong: true
                                },
                                {
                                    id: 2012,
                                    content: '获取您的蓝牙功能，用于辅助精准定位及开锁服务',
                                    strong: true
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 3,
                    title: '如何管理您的信息',
                    children: [
                        {
                            id: 301,
                            content: '您可以通过福马的平台，访问、更正、删除我们收集的关于您的个⼈信息，并管理权限授权或撤回权限授权。您可以通过福马平台注销账号。除法律法规另有规定外，注销账号之后我们将根据本协议约定保存您的个⼈信息，保存期限届满后我们将对您的个⼈信息进⾏删除或者匿名化处理。',
                            strong: false
                        }
                    ]
                },
                {
                    id: 4,
                    title: '如何联系我们',
                    children: [
                        {
                            id: 401,
                            content: ' 您可以通过电话与我们取得联系。',
                            strong: false
                        }
                    ]
                },
                {
                    id: 5,
                    title: '福马个人信息处理规则',
                    children: [
                        {
                            id: 501,
                            content: '定义与适⽤范围',
                            strong: false,
                            children: [
                                {
                                    id: 5010,
                                    content: '个⼈信息，是以电⼦或者其他⽅式记录的与已识别或者可识别的⾃然⼈有关的各种信息，不包括匿名化处理后的信息。',
                                    strong: false
                                },
                                {
                                    id: 5011,
                                    content: '敏感个⼈信息，是⼀旦泄露或者非法使⽤，容易导致⾃然⼈的⼈格尊严受到侵害或者⼈⾝、财产安全受到危害的个⼈信息',
                                    strong: false
                                },
                                {
                                    id: 5012,
                                    content: '匿名化，是指个⼈信息经过处理无法识别特定⾃然⼈且不能复原的过程。',
                                    strong: false
                                }
                            ]
                        },
                        {
                            id: 502,
                            content: '个⼈信息的收集',
                            strong: false,
                            children: [
                                {
                                    id: 5021,
                                    content: '通过“微信登录”⽅式登录福马无人车，我们还需要获取您在微信平台的OpenID。我们以此识别您在福马平台的⽹络⾝份，并为您提供福马平台服务。如果您拒绝提供⼿机号码，福马平台将无法为您创建账号并提供服务。 ',
                                    strong: false
                                },
                                {
                                    id: 5022,
                                    content: ' 当您进入App/⼩程序时，我们会收集您的位置信息。',
                                    strong: false
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 6,
                    title: '设备权限调用',
                    children: [
                        {
                            id: 601,
                            content: '您同意本个⼈信息处理规则后，相应⼿机权限并不会默认开启，当涉及重要或敏感的⼿机权限时，我们会在您使⽤到相应功能时，另⾏弹窗再次征得您的同意后开启。权限开启后，您可以在设备的设置功能中选择关闭部分或全部权限，从⽽拒绝福马收集相应的个⼈信息。使⽤Android系统的不同设备中，权限显示⽅式及关闭⽅式可能有所不同，如果无法找到相应功能，可询问设备及系统的提供⽅获得帮助。',
                            strong: false
                        }
                    ]
                },
                {
                    id: 7,
                    title: '个⼈信息的共享、转让、公开披露',
                    strong: false,
                    children: [
                        {
                            id: 701,
                            content: '共享是指福马向其他个⼈信息处理者提供个⼈信息，且双⽅分别对个⼈信息拥有独立控制权的过程。福马不会对外共享您的个⼈信息，但以下情况除外:',
                            strong: false,
                            children: [
                                {
                                    id: 7010,
                                    content: '在获取明确同意情况下的共享: 获得您的明确同意后，滴滴会向您指定的第三⽅共享您授权范围内的信息。',
                                    strong: false
                                },
                                {
                                    id: 7011,
                                    content: '在法定情形下的共享: 福马可能会根据法律法规规定、诉讼争议解决需要，或按⾏政、司法机关依法提出的要求，对外共享您的个⼈信息。',
                                    strong: false
                                },
                                {
                                    id: 7012,
                                    content: '如发⽣收购、兼并、重组等变更，我们会要求变更后的主体依然遵守本个⼈信息处理规则约定，履⾏原有责任及义务。如变更后的主体需变更个⼈信息使⽤⽬的，我们会要求其事先获得您的明示同意。对内⽽⾔，在之前的收购、兼并活动中，对于被收购、被兼并对象管理的数据（包括⽤户个⼈信息）均采⽤与滴滴⾃有数据相同的标准和要求进⾏处理和保护。',
                                    strong: false
                                },
                                {
                                    id: 7013,
                                    content: '我们将遵守相关法律法规，对您的个⼈信息予以保密。除非事先获得您的明确同意或授权，或依照法律规定所必须，或为了保护其他相关⽅重⼤合法权益且对个⼈信息进⾏去标识化处理的，我们不会公开披露您的个⼈信息。',
                                    strong: false
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 8,
                    title: '依法不需取得个⼈同意的情形',
                    strong: false,
                    children: [
                        {
                            id: 800,
                            content: '为订立、履⾏个⼈作为⼀⽅当事⼈的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施⼈⼒资源管理所必需；',
                            strong: false
                        },
                        {
                            id: 801,
                            content: '为履⾏法定职责或者法定义务所必需；',
                            strong: false
                        },
                        {
                            id: 802,
                            content: '为应对突发公共卫⽣事件，或者紧急情况下为保护⾃然⼈的⽣命健康和财产安全所必需；',
                            strong: false
                        },
                        {
                            id: 803,
                            content: '为公共利益实施新闻报道、舆论监督等⾏为，在合理的范围内处理个⼈信息；',
                            strong: false
                        },
                        {
                            id: 804,
                            content: '依照法律规定在合理的范围内处理个⼈⾃⾏公开或者其他已经合法公开的个⼈信息；',
                            strong: false
                        },
                        {
                            id: 805,
                            content: '法律、⾏政法规规定的其他情形。',
                            strong: false
                        }
                    ]
                },
                {
                    id: 9,
                    title: '变更',
                    strong: false,
                    children: [
                        {
                            id: 900,
                            content: '如福马平台发⽣以下变化，我们将及时对本个⼈信息处理规则进⾏相应的修订:',
                            strong: false,
                            children: [
                                {
                                    id: 901,
                                    content: '⽤户个⼈信息的处理⽬的、处理⽅式和处理的个⼈信息种类发⽣变更；',
                                    strong: false
                                },
                                {
                                    id: 902,
                                    content: '⽤户个⼈信息保存地域发⽣变更；',
                                    strong: false
                                },
                                {
                                    id: 903,
                                    content: '我们的联络⽅式及投诉渠道发⽣变更；',
                                    strong: false
                                },
                                {
                                    id: 904,
                                    content: '发⽣其他可能影响⽤户个⼈信息安全或影响⽤户个⼈信息权利的变更等。',
                                    strong: false
                                },
                                {
                                    id: 905,
                                    content: '发⽣其他可能影响⽤户个⼈信息安全或影响⽤户个⼈信息权利的变更等。',
                                    strong: false
                                }
                            ]
                        },
                        {
                            id: 901,
                            content: '个⼈信息处理规则修订后，我们会在滴滴平台发布最新版本，并重新征得您的同意。',
                            strong: false
                        },
                        {
                            id: 902,
                            content: '未经您的明确同意，我们不会削减您按照原个⼈信息处理规则所享有的权利。',
                            strong: false
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        numberToWord: function (number) {
            var word = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
            // 0 <= number < 20000
            if (number <= 10) {
                return word[number];
            }
            else {
                var second = number % 10;
                return '十' + word[second];
            }
        }
    }
});
