import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Deal',
    data: function () {
        return {
            dealList: [
                {
                    id: 1,
                    title: '服务内容',
                    children: [
                        {
                            id: 101,
                            content: '我们根据您的出行需求通过实时的线上线下信息交互，借助大数据分析的有效匹配向您提供网约车服务（以下简称“服务”），您可通过小拉出行选择并使用上述服务。',
                            strong: false
                        },
                        {
                            id: 102,
                            content: '您通过小拉出行向我们发出服务需求信息后，我们将根据您的需求信息和各地方法律法规匹配符合您需求的车辆，并由最终匹配成功的平台驾驶员按照平台规则向您提供线下运输服务。',
                            strong: true
                        },
                        {
                            id: 103,
                            content: '我们将做出合理的努力，让您获得所需的出行服务，但小拉出行是否能够成功提供网约车服务，受制于您发布需求时所在位置周边是否有可提供服务的车辆。',
                            strong: true
                        }
                    ]
                },
                {
                    id: 2,
                    title: '账号注册与信息使用规则',
                    children: [
                        {
                            id: 201,
                            content: '您理解并同意，您通过注册福马智行无人车账号以使用福马智行无人车相关服务。您承诺向福马智行所提供的信息真实、准确、完整。福马智行有权验证您所提供的信息的真实性，当查验您提供的信息缺乏真实性、准确性时，福马智行有权拒绝向您提供服务或拒绝您使用有关网站、服务、应用程序、对您信息的管理及限制。',
                            strong: false
                        },
                        {
                            id: 202,
                            content: '当您签订本协议时，您应具备完全民事行为能力（年满18周岁或年满16周岁、以自己的劳动收入为主要生活来源，且精神健康能完全辨认自己的行为）。',
                            strong: false
                        },
                        {
                            id: 203,
                            content: '如您年满12周岁但尚未具备完全民事行为能力，请在征得您的监护人同意后签署本协议。如您未经监护人同意即签署本协议，因您已具备相应的智力、判断水平，本协议仍然有效，因本协议及您的行为产生的义务和责任将由您或您的监护人承担。对于您的监护人未妥善履行监护责任的情况下，导致您自身损失或给他人造成损失的，将由您的监护人承担相应责任。',
                            strong: false
                        },
                        {
                            id: 204,
                            content: '如您尚未年满12周岁或存在其他无民事行为能力的情况，请勿签署本协议或在无监护人陪同的情况下使用该服务。如您违反本条要求，导致自身损失或给他人造成损失的，将由您的监护人承担相应责任。',
                            strong: false
                        },
                        {
                            id: 205,
                            content: '您应谨慎合理的保存、使用用户名和密码，并对通过您的账号和密码实施的所有行为、活动及事件负全责。您若发现任何不当使用用户账号或存在安全漏洞等其他可能危及您账号安全的情况，或发现账号存在安全漏洞等情况，应立即通知福马以采取措施予以处理。',
                            strong: false
                        },
                        {
                            id: 206,
                            content: '您理解并同意，在您使用福马智行的相关服务时，您同意福马智行依据《用户隐私政策》的规定执行相关个人信息的收集、使用和共享。福马智行亦有权应司法及行政部门的要求，向其提供您的相关信息。',
                            strong: false
                        },
                        {
                            id: 207,
                            content: '您理解并同意，福马智行可能会与第三方合作而向您提供相关的功能，在此情况下，如该第三方同意承担与福马智行同等的隐私保护责任，则福马智行有权将您的相关资料等提供给该第三方，此外，在不透露您隐私的前提下，福马智行有权对与您账户相关的整个数据库进行分析并对相关的数据库进行商业上的利用。',
                            strong: false
                        },
                        {
                            id: 208,
                            content: '您的账户被注销后，福马智行没有义务为您保留或向您披露账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。',
                            strong: false
                        },
                        {
                            id: 209,
                            content: '您同意，福马智行停止向您提供服务后，福马智行仍享有下列权利：',
                            strong: false,
                            children: [
                                {
                                    id: 2091,
                                    content: '继续保存您未及时删除的信息及使用福马智行期间发布的所有信息至法律规定的记录保存期两年。',
                                    strong: true
                                },
                                {
                                    id: 2092,
                                    content: '您在使用期间存在违法行为或违反本条款和/或服务规则的行为的，福马智行仍可依据本规则向您主张权利。',
                                    strong: true
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 3,
                    title: '服务使用',
                    children: [
                        {
                            id: 301,
                            content: '您作为乘客，应当保证发布出行需求信息时是自愿的，且不存在意识不清楚、醉酒等情形。若您有意识不清楚、醉酒等情形的，陪同人员代为发布的出行需求信息视为您的行为；在您醉酒或存在其他意识不清楚的情况下，应当由意识清楚的第三方陪同；在您饮酒、醉酒或意识不清楚的情况下，给他人造成损失的，您应当就相应损失予以赔偿。',
                            strong: false
                        },
                        {
                            id: 302,
                            content: '为防止司机私自更换车辆、出借账号等行为的发生，您在乘坐车辆前，请仔细核对车辆及司机是否与小拉出行平台所显示的车辆、司机信息一致。如发现车辆及司机与小拉出行展示的不一致，应拒绝乘坐，并立即向客服投诉，小拉出行核实后将对司机、司机所属公司予以追责。',
                            strong: false
                        },
                        {
                            id: 303,
                            content: '您不得携带危险品或者其他法律、法规规定禁止或不适宜携带的物品搭乘司机的车辆，否则司机有权拒绝提供服务，并由您承担任何携带该等物品引起的后果和责任。',
                            strong: false
                        },
                        {
                            id: 304,
                            content: '您不得在不乘坐车辆的情况下，委托司机单独运送物品。对于违反平台规则要求运送物品的订单，驾驶员有权拒绝并取消行程。',
                            strong: false
                        },
                        {
                            id: 305,
                            content: '在您使用网约车服务过程中获得的用户的个人信息，除相关法律允许外，您不得向任何第三方公开、透露上述个人信息。',
                            strong: false
                        },
                        {
                            id: 306,
                            content: '在使用网约车服务过程中，您不得在未获得车辆驾驶员事先同意的情况下进行照相录像，获得同意后进行照相、录音、录像所获得的内容仅用于记录服务过程的证明目的，不得通过网络传输等方式进行传播、扩散。',
                            strong: false
                        },
                        {
                            id: 307,
                            content: '对于您(含实际乘车人)在使用小拉出行服务的过程中，发生下列不当行为的，小拉出行有权暂停/终止向您提供服务，并要求您承担相应的人身及财产损失，因不当行为造成车辆停驶的，还应按照同等车辆市场租金标准承担车辆停驶期间的损失及按照该受损驾驶员的平均收入承担该驾驶员的误工费; 行为严重的，配合行政及司法机关追究您方法律责任。',
                            strong: false,
                            children: [
                                {
                                    id: 3071,
                                    content: '存在违法违规行为的，包括但不限于伤害驾驶员或同乘车人身心的，诈骗、盗窃、抢劫等非法手段损害驾驶员或同乘车人财物的，扰乱社会秩序、平台秩序的；',
                                    strong: true
                                },
                                {
                                    id: 3072,
                                    content: '存在安全隐患行为的，包括但不限于抢夺方向盘、钥匙、操纵杄等妨碍车辆正常行驶或驾驶员安全驾驶的，恶意投诉、制造或传播谣言等扰乱平台正常运营的；',
                                    strong: true
                                },
                                {
                                    id: 3073,
                                    content: '存在不文明行为的，包括但不限于辱骂、威胁、恐吓驾驶员或同乘车人的，裸露器官或播放淫秽视频的，污损车辆拒不赔付的，损毁、扣押车辆的；',
                                    strong: true
                                },
                                {
                                    id: 3074,
                                    content: '存在其它违反法律法规、国家及地方政策、社会风俗的行为的。',
                                    strong: true
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 4,
                    title: '义务、责任及限制',
                    children: [
                        {
                            id: 401,
                            content: '您知悉并确认，从需求用户发送订单并由系统匹配开始，至订单完成之时，为线上网约车交易信息服务区间（“网约车服务区间”），其中从乘客肢体接触到线下服务车辆起始，至线下运输服务结束乘客离开运输工具止，为司机为用户提供线下网约车运输服务区间。小拉出行在网约车服务提供区间内，根据法律法规的要求承担相应的法律责任。',
                            strong: false
                        },
                        {
                            id: 402,
                            content: '小拉平台在以上网约车服务区间内，根据适用法律法规的要求承担相应的法律责任。平台参与方在网约车服务提供区间内，根据法律法规的要求承担相应的法律责任。同时，为提升服务品质，保障平台用户（包括驾驶员与乘客）安全，并更好的承担企业社会责任，我们将通过个性化的安全保证产品为特定受保障用户提供相应的安全保障服务。对于存在侵权责任人、违约责任人的，在提供安全保障服务后，提供安全保障服务的相关方有权向实际责任方追偿。',
                            strong: false
                        },
                        {
                            id: 403,
                            content: '我们向您提供的服务信息仅供您参考，并不构成保证。我们将在合理的范围内尽力保证该等信息准确，但无法保证其中没有任何错误。',
                            strong: false
                        },
                        {
                            id: 404,
                            content: '如您违反本协议约定，我们有权根据您违约的严重程度对您采取暂停服务、终止服务或其他限制措施，您应赔偿我们所受损失及我们因此支出的合理费用并承担相应的违约责任。',
                            strong: false
                        },
                        {
                            id: 405,
                            content: '如因您违反本协议约定，导致我们被第三方追责，我们在向第三方承担责任后，有权向您追偿，追偿内容包括我们所受损失及因此支付的合理费用。',
                            strong: false
                        },
                        {
                            id: 406,
                            content: '如您的行为使第三方遭受损失且您未及时依照法律法规、本协议约定、小拉出行规则规定等承担责任的，出于维护乘车秩序、保护用户合法权利的目的，您同意委托我们代您支付上述款项，并偿还我们因此支付的全部费用。',
                            strong: false
                        },
                        {
                            id: 407,
                            content: '本条款所称“损失”，包括经济损失、商誉损失等。本条款所称“因此支出的合理费用”，包括诉讼费、律师费、鉴定费、公证费、差旅费等。',
                            strong: false
                        },
                        {
                            id: 408,
                            content: '来源于外部的风险，比如违法犯罪活动、交通事故等意外事件等造成的损失，由相关责任方承担，小拉出行将会积极协助相关部门、保险公司等进行处理。对于您在接受服务过程中发生的不可抗力事件(根据服务性质，包括交通瘫痪、堵车等事件)，小拉出行也会在合理范围内进行协助，但不对因此造成的损失承担责任。',
                            strong: false
                        },
                        {
                            id: 409,
                            content: '小拉出行将根据《中华人民共和国电子商务法》等法律法规要求，对服务提供方予以审慎审核(包括但不限于身份审核等)，并记录、保存平台上发布的服务信息、交易信息，以积极保障您的人身、财产安全，但鉴于平台存在海量信息及客观上信息与实物相分离的特点，小拉出行客观上无法逐一实质审查每一服务的信息，除法律法规强制性规定外，小拉出行不对平台上的任何第三方所提供服务的真实性、适用性、合法性、安全性提供任何形式的明示或默示的担保、声明或承诺。如您在使用小拉出行的过程中发现平台中的相关服务信息违反法律规定，您可及时向小拉出行或有关机关举报或投诉，小拉出行将在收到您的举报或投诉时釆取相应的核实与处理措施。',
                            strong: false
                        },
                        {
                            id: 410,
                            content: '小拉出行在为您提供服务时，可能会引入第三方服务商提供的服务(第三方支付服务、查询服务等)。您知悉、理解并同意，您使用小拉出行账号访问或使用第三方服务商提供的服务时将跳转至第三方服务商的独立平台。第三方服务商并非小拉出行，亦不通过小拉出行向您提供任何服务或达成任何交易。小拉出行无法实质审核、控制第三方服务商及其所提供的具体服务或对第三方服务予以任何形式的担保或承诺。您在访问或使用第三方服务商的服务前应自行核实第三方服务商的身份、资质、服务内容等相关信息，仔细约定第三方服务商的相关服务协议或规则，并自行选择是否进行本协议下的授权操作并使用小拉出行账号接受相关第三方服务商服务，小拉出行将不就您所接受的第三方服务商服务向您承担任何责任。您拟接受的第三方服务将由该第三方服务商独立运营并独立承担全部责任。如有争议，请您根据与第三方服务商所订立的相关服务协议等相应协议或规则，联系第三方服务商予以解决。',
                            strong: false
                        }
                    ]
                },
                {
                    id: 5,
                    title: '知识产权',
                    children: [
                        {
                            id: 501,
                            content: '福马智行所展示的各系统由福马智行自主开发、提供技术支持，并对福马智行平台服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。福马智行提供各项服务时所依托软件的著作权、专利权，所使用的各项商标、商业形象、商业标识、技术诀窍，其著作权、商标权及其他各项相关权利均归福马智行所有。',
                            strong: false
                        },
                        {
                            id: 502,
                            content: '您承诺，不得将福马智行软件及有关服务用作任何其他商业用途；不得以不同名义注册并使用多个乙方用户账号。',
                            strong: false
                        },
                        {
                            id: 503,
                            content: '除在本第5.1条下授予的许可外，本协议并不授予您对小拉出行软件的知识产权的任何其他许可或权利。',
                            strong: false
                        },
                        {
                            id: 504,
                            content: '您承诺，在使用乙方软件时上传、提交、存储或发布的内容（包括但不限于文字、数据、图片、视频、音频、动画等）的知识产权均属于您所有或已获合法及适当的授权，您上传、提交、存储或发布的行为不会侵犯他人的知识产权或其他合法权益。如果任何第三方提出关于上述内容的知识产权异议，我方有权根据实际情况删除相关的内容，且有权追究您的法律责任，给我方或任何第三方造成任何损失的，您应负责全额赔偿。',
                            strong: false
                        }
                    ]
                },
                {
                    id: 6,
                    title: '隐私保护',
                    children: [
                        {
                            id: 601,
                            content: '在您注册、使用应用程序及提供服务过程中，您同意并提供手机位置数据、电话号码等个人信息，此类信息采集和使用将仅限于满足无人车服务，我们将采取合理的措施保护您的个人隐私，除法律或有法律赋予权限的政府部门要求或您同意等原因外，我们未经您同意不会向任何非必要的第三方公开、透露您的个人信息。',
                            strong: false
                        }
                    ]
                },
                {
                    id: 7,
                    title: '不可抗力'
                }
            ]
        };
    },
    methods: {
        numberToWord: function (number) {
            var word = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
            // 0 <= number < 20000
            if (number <= 10) {
                return word[number];
            }
            else {
                var second = number % 10;
                return '十' + word[second];
            }
        }
    }
});
